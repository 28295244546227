import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';

import { PieChartOutlined, MobileOutlined, DiffOutlined } from '@ant-design/icons';
import { Badge, Divider, Menu, Select, Spin } from 'antd';

import { Context } from '../helpers/Context';
import Version from './Version';
import DiScrollspy from './DiScrollspy';

//costruzione del singolo elemento di menù
function getItem(label, key, icon, className, children) {
  return {
    key,
    icon,
    children,
    label,
    className
  };
}

//elementi del menu e del menu secondario


function DashboardMenu({hideShops}) {

  const [items, setItems] = useState([])
  const navigate = useNavigate();

  const {
    shopsListLoading,
    isRestaurant,
    setShopName,
    shopsList,
    setShop,
    setOpen,
    shop,
  } = useContext(Context);

  // function logout(){ 
  //   localStorage.clear("__access_token");
  //   navigate("/");
  // };
  
  useEffect(()=>{
    const items = 
      [
        getItem('Generali', 'dashboard', <PieChartOutlined />),
        getItem(<>Confronta <span className='fs-8 grey absolute top right opacity-05'>BETA</span></>, 'compare', <DiffOutlined />),
        getItem('Indietro', 'indietro',<MobileOutlined />),
        //getItem('Operatori', 'operators', <TeamOutlined />),
      ];
    
      setItems(items);
      
  },[])

  function clickItem(e){ 
    if(e.key === "indietro"){
      window.location.href = "https://www.dylogapp.it";
      return;
    }
    navigate("/" + e.key);
  };
  console.log(!window.location.pathname.split("/")[1])
  return (
    <>
      {/* menu principale */}
      <Menu className="di-navigation primary" theme="light" selectedKeys={window.location.pathname.split("/")[1]} mode="inline" items={items} onClick={clickItem}/>
      {/* menu secondario */}

      {!window.location.pathname.split("/")[1] || window.location.pathname === "/dashboard" ?
        <>
          <Divider/>
            <DiScrollspy />
          <Divider />
        </>
        :
        <></>
      }
      
      {!hideShops &&
        <div className='secondary-menu'>
          {/* filtri per cambiare statistiche rapide sull'ora corrente o tutto il giorno precedente */}
              <Divider >
                <span className='m-2'>{`Filtra per ${!isRestaurant ? "Negozio" : "Ristorante "}`}</span>

                {/* <Tooltip color="#008833" placement="bottom" className="cursor-pointer color-primary di-chart-tooltip fs-18" title="Scegli un negozio specifico per cui vedere i dati">
                  <QuestionCircleOutlined/>
                </Tooltip> */}
                
              </Divider>
              <Spin spinning={shopsListLoading}>
                <Select
                  showSearch
                  placeholder="Seleziona Negozio"
                  optionFilterProp="children"
                  className='w-100'
                  allowClear
                  value={shop}
                  onClear={()=>{
                    setShop("");
                    setShopName("");
                    setOpen(false);
                  }}
                  onChange={
                    (value, i) => {
                      if (!i) return;
                      setShopName(i.label);
                      setOpen(false);
                      setShop(value ?? "");
                      sessionStorage.setItem("__di_public_code_filter",value)
                    }
                  }
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={shopsList}
                />
              </Spin>
              {/* filtri per cambiare statistiche rapide sull'ora corrente o tutto il giorno precedente */}
        </div>
      }
    </>
  );
}

export default DashboardMenu;