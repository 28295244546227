import React, { useContext, useEffect, useState } from 'react'

import dayjs from 'dayjs';

import { SaveOutlined, UndoOutlined,FundProjectionScreenOutlined } from '@ant-design/icons';
import { Button, Col, InputNumber, Row, Select, Switch} from 'antd';

import { ENDPOINT_SOLD_ITEMS, LOCAL_SELLED_PRODUCT_COLOR } from '../../helpers/Globals';
import duplicateArray from '../../helpers/DuplicateArray';
import DownloadCsvButton from '../DownloadCsvButton';
import DiPaletteSelector from '../DiPaletteSelector';
import { Context } from '../../helpers/Context';
import ChartSkeleton from '../ChartSkeleton';
import DiRangePicker from '../DiRangePicker';
import { ApiCall } from '../../Backoffice';
import BarChart from '../charts/BarChart';
import DiTooltip from '../DiTooltip';
import NoData from '../NoData';

export default function DisplaySelledProduct() {
   

   const [selledProductColor, setSelledProductColor] = useState(localStorage.getItem(LOCAL_SELLED_PRODUCT_COLOR) ? localStorage.getItem(LOCAL_SELLED_PRODUCT_COLOR) : "pastel2");
   const [selledProductCategoriesFilterActive, setSelledProductCategoriesFilterActive] = useState([]); //lista delle categorie di prodotti che voglio vedere nel grafico
   const [sdSelledProduct, setSdSelledProduct] = useState(dayjs().format('YYYY-MM-DDT00:00:00'));
   const [edSelledProduct, setEdSelledProduct] = useState(dayjs().format('YYYY-MM-DDT23:59:59'));
   const [selledProductCategoriesFilter, setSelledProductCategoriesFilter] = useState([]); //filtro delle categorie dei prodotti venduti
   const [selledProductEarnOriginal, setSelledProductEarnOriginal] = useState([]); //selled product earn mai modificati con tutti i dati completi (non limitati ad un numero specifico di elementi)
   const [showSelledProductEarn, setShowSelledProductEarn] = useState(false);
   const [resetSelledProductDate, setResetSelledProductDate] = useState(0); 
   const [loadingSelledProduct, setLoadingSelledProduct] = useState(false);
   const [selledProductOriginal, setSelledProductOriginal] = useState([]); //selled product mai modificati con tutti i dati completi (non limitati ad un numero specifico di elementi)
   const [selledProductLimit, setSelledProductLimit] = useState(10);
   const [selledProductEarn, setSelledProductEarn] = useState([]); //guadagno da ogni prodotto
   const [selledProduct, setSelledProduct] = useState([]); //parliamo della quantità venduta
      
   const [totalProductSales, setTotalProductSales] = useState(0);

  // ---- variabili dal context -----
   const {
      commonQueryParams,
      refreshVariables,
      accessToken,
   } = useContext(Context);
   // ---- variabili dal context -----
   
   
   // gestione del range date per il totale venduto prodotti
   const selledProductRange = (dates, dateStrings) => {

      if (dates) {

      var newStartDate = dateStrings[0].replaceAll("/", "-").replaceAll(/\s/g,'T');
      var newEndDate = dateStrings[1].replaceAll("/", "-").replaceAll(/\s/g,'T');
      setSdSelledProduct(newStartDate);
      setEdSelledProduct(newEndDate);

      } else {
         setResetSelledProductDate(resetSelledProductDate + 1);
      }
   };


   const changeSelledProductCategoriesFilter = (value) => {
      //---------- risetto i prodotti venduti solamente con le categorie selezionate ----------
      setSelledProductCategoriesFilterActive(value);
      if(value.length > 0){
         let filteredSelledProductOriginal = [];
         let filteredSelledProductEarnOriginal = [];

         for(let a of value){
            selledProductOriginal.filter(x => x.category === a).forEach((e)=>filteredSelledProductOriginal.push(e));
            selledProductEarnOriginal.filter(x => x.category === a).forEach((e)=>filteredSelledProductEarnOriginal.push(e));
         }
         
         var tempSelledProductData = filteredSelledProductOriginal.sort((a,b) => b["Quantità vendute"] - a["Quantità vendute"]);
         var tempSelledProductEarn = filteredSelledProductEarnOriginal.sort((a,b) => b["Incasso totale"] - a["Incasso totale"]);
         
         setSelledProduct(tempSelledProductData.slice(0,selledProductLimit))
         setSelledProductEarn(tempSelledProductEarn.slice(0,selledProductLimit))
      }else{
      setSelledProduct(selledProductOriginal.slice(0,selledProductLimit)); 
      setSelledProductEarn(selledProductEarnOriginal.slice(0,selledProductLimit)); 
      }
      //---------- risetto i prodotti venduti solamente con le categorie selezionate ----------
   };
   
   // settare la data giornaliera dei prodotti venduti
   useEffect(()=>{

      var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
      var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

      setSdSelledProduct(startDate)
      setEdSelledProduct(endDate)

   }, [resetSelledProductDate])
   

          
   //chiamata per ricevere i dati venduto prodotti per periodo
   //ricevere il csv aggiungere alla richiesta alla fine &produce_csv=1
   useEffect(()=>{
      setLoadingSelledProduct(true);
      setSelledProductCategoriesFilterActive([]);
      (async () => { 
      const json = await ApiCall(`${ENDPOINT_SOLD_ITEMS}?date=${sdSelledProduct}&end_date=${edSelledProduct}&${commonQueryParams}`, "GET", `${accessToken}`, null)
      if(json){

         var totalProductQuantity = 0;
         var tempSelledProductData = duplicateArray(json);
         var tempSelledProductEarn = duplicateArray(json);
         var selledProductCategories = [];

         for (let i = 0; i < tempSelledProductData.length; i++) {
        
            totalProductQuantity = totalProductQuantity + tempSelledProductData[i]["quantity"];

            //modifica delle chiavi prodotto in etichette per i grafici
            tempSelledProductData[i]["Prodotto"] = tempSelledProductData[i]["description"];

            tempSelledProductData[i]["Quantità vendute"] = tempSelledProductData[i]["quantity"];

            //creo la lista di categorie univoche per filtrare i prodotti venduti
            var category = {
               "label":tempSelledProductData[i]["category"],
               "value":tempSelledProductData[i]["category"],
            };
            if (!selledProductCategories.some((element) => element.label === category.label)) selledProductCategories.push(category);
           
         }
         for (let i = 0; i < tempSelledProductEarn.length; i++) {
            //modifica delle chiavi prodotto in etichette per i grafici
            tempSelledProductEarn[i]["Prodotto"] = tempSelledProductEarn[i]["description"];
            tempSelledProductEarn[i]["Incasso totale"] = tempSelledProductEarn[i]["amount"].toFixed(2);
         }

         // funzione per rimuovere il servizio nei dati
         tempSelledProductData = tempSelledProductData.reduce((acc, item) => {
            if (item.description !== "SERVIZIO") {
               acc.push(item);
            }
            return acc;
         }, []);

         // -–----------------- fondo i valori dei prodotti con lo stesso nome -–----------------- 


         var mergedSelledProductEarn = tempSelledProductEarn.reduce((acc, item) => {
            const index = acc.findIndex(i => i["Prodotto"] === item["Prodotto"]);
            index !== -1 ? (acc[index]["Incasso totale"] = (parseFloat(acc[index]["Incasso totale"]) + parseFloat(item["Incasso totale"])).toFixed(2).toString()) : acc.push(item);
            return acc
         }, []);

         var mergedSelledProductData = tempSelledProductData.reduce((acc, item) => {
            const index = acc.findIndex(i => i["Prodotto"] === item["Prodotto"]);
            index !== -1 ? (acc[index]["Incasso totale"] = (parseFloat(acc[index]["Quantità vendute"]) + parseFloat(item["Quantità vendute"])).toFixed(2).toString()) : acc.push(item);
            return acc
         }, []);

         mergedSelledProductData = mergedSelledProductData.sort((a,b) => b["Quantità vendute"] - a["Quantità vendute"]);
         mergedSelledProductEarn = mergedSelledProductEarn.sort((a,b) => b["Incasso totale"] - a["Incasso totale"]);
         
         // -–----------------- fondo i valori dei prodotti con lo stesso nome -–----------------- 
         
         setSelledProductEarn(mergedSelledProductEarn.slice(0, selledProductLimit));
         setSelledProduct(mergedSelledProductData.slice(0, selledProductLimit));
         setSelledProductEarnOriginal(mergedSelledProductEarn);
         setSelledProductOriginal(mergedSelledProductData);
         setTotalProductSales(totalProductQuantity);
         setLoadingSelledProduct(false);
            
         setSelledProductCategoriesFilter(selledProductCategories);
      }

      })(); 


   }, [sdSelledProduct, ...refreshVariables]);
   


   return (
      <div className="di-card h-100">
      <Row>
         <Col span={24}>
            <div className='flex column align-center space-between gap-10'>
            <div className='flex gap-10 color-grey fs-15'>
               <div className='flex align-center gap-5'><FundProjectionScreenOutlined className="color-primary fs-20"/> Prodotti venduti</div>
               <DiTooltip text="Intervallo temporale di quali prodotti sono stati venduti con pezzi e totale importo (default giornaliero)"/>
            </div>
            <div className='flex align-center gap-10 space-between w-100 flex-wrap'>
               <div className='flex column'>
              <DiRangePicker onChange={selledProductRange}/>
               <div className='flex gap-10 mt-10'>
                  <Select
                     maxTagCount='responsive'
                     showSearch
                     allowClear
                     mode="multiple"
                     placeholder="Seleziona Categorie"
                     optionFilterProp="children"
                     className='w-100'
                     value={selledProductCategoriesFilterActive}
                     onChange={(value)=>changeSelledProductCategoriesFilter(value)}
                     filterOption={(input, option) =>
                     (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                     }
                     options={selledProductCategoriesFilter}
                  />
                  {selledProductCategoriesFilterActive.length > 0 &&
                  <Button
                     type="primary"
                     shape="circle"
                     icon={<SaveOutlined />}
                     onClick={() => localStorage.setItem("__di_selled_product_categories_active", JSON.stringify(selledProductCategoriesFilterActive))}
                  />  
                  }
                  {localStorage.getItem("__di_selled_product_categories_active") && JSON.parse(localStorage.getItem("__di_selled_product_categories_active")).length > 0 &&
                  <Button
                     type="primary"
                     shape="circle"
                     icon={<UndoOutlined />}
                     onClick={() => changeSelledProductCategoriesFilter(JSON.parse(localStorage.getItem("__di_selled_product_categories_active")))}
                  />  
                  }
               </div>
               </div>
               <div className='flex gap-10'>
                  <DiPaletteSelector
                     defaultValue={selledProductColor}
                     onChange={
                        (value)=>{
                           setSelledProductColor(value);
                           localStorage.setItem(LOCAL_SELLED_PRODUCT_COLOR,value)
                        }
                     }
                  />
               {showSelledProductEarn ?
                  <DownloadCsvButton
                  key={"csvSelledProductEarn"}
                  dataToDownload={selledProductEarn}
                  filename={"prezzo-prodotti-venduti"}
                  />
                  :
                  <DownloadCsvButton
                  key={"csvSelledProduct"}
                  dataToDownload={selledProduct}
                  filename={ "quantità-prodotti-venduti"}
                  />
               }
               </div>
            </div>
            <div className='flex gap-10 w-100 justify-end align-center mt-10'>
               {/* indicatore del numero di prodotti da visionare */}
               <div className='flex gap-5 flex-wrap mb-20 align-center'>
                  <div>Limite prodotti:</div>
                  <InputNumber
                  min={1} 
                  max={50} 
                  defaultValue={10}
                  onChange={(n)=>{

                     setSelledProductLimit(n);
                     //---------- ogni volta che aumento il limitatore rifiltro i prodotti a display e li limito ----------
                     if(selledProductCategoriesFilterActive.length > 0){
                        let filteredSelledProductOriginal = [];
                        let filteredSelledProductEarnOriginal = [];
                        for(let a of selledProductCategoriesFilterActive){
                        selledProductOriginal.filter(x => x.category === a).forEach((e)=>filteredSelledProductOriginal.push(e));
                        selledProductEarnOriginal.filter(x => x.category === a).forEach((e)=>filteredSelledProductEarnOriginal.push(e));
                        }

                        var tempSelledProductData = filteredSelledProductOriginal.sort((a,b) => b["Quantità vendute"] - a["Quantità vendute"]);
                        var tempSelledProductEarn = filteredSelledProductEarnOriginal.sort((a,b) => b["Incasso totale"] - a["Incasso totale"]);
         

                        setSelledProduct(tempSelledProductData.slice(0,n))
                        setSelledProductEarn(tempSelledProductEarn.slice(0,n))
                     }else{
                        setSelledProduct(selledProductOriginal.slice(0,n)); 
                        setSelledProductEarn(selledProductEarnOriginal.slice(0,n)); 
                     }
                     //---------- ogni volta che aumento il limitatore rifiltro i prodotti a display e li limito ----------

                  }}
                  />
               </div>
            
               {/* switcher totale orario / giornaliero */}
               <div className='flex gap-5 flex-wrap mb-20 align-center'>
               <div>Mostra prodotti:</div>
               <Switch
                  checkedChildren="Incasso" 
                  unCheckedChildren="Quantità" 
                  onChange={()=>setShowSelledProductEarn(showSelledProductEarn ? false : true)}
               />
               </div>
            </div>

            </div>
         </Col>
      </Row>
      {/* range di date per prodotti venduti e switcher del grafico*/}

      {/* grafico dei selled product */}
      { 
            !loadingSelledProduct && selledProduct.length !== 0 ? 
            <BarChart
               xIndex="Prodotto" 
               yIndex={showSelledProductEarn ? "Incasso totale" : "Quantità vendute" }
               colors={selledProductColor} 
               suffix={showSelledProductEarn && "€"}
               data={!showSelledProductEarn ? selledProduct : selledProductEarn} 
               layout="horizontal" 
               margin={window.innerWidth < 1000 ?  { top: 80, right: 10, bottom: 20, left: 100 } : { top: 20, right: 50, bottom: 50, left: 120 }}
            /> 
         :
            !loadingSelledProduct && selledProduct.length === 0 ? <NoData/>
         :
            loadingSelledProduct ? <ChartSkeleton/> 
         : 
         ""
      }
      {/* grafico dei selled product */}
   </div>
  )
}
