import React, { useContext, useEffect, useState } from 'react'

import dayjs from 'dayjs';

import { ArrowDownOutlined, ArrowUpOutlined, CalendarOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, InputNumber, Popover, Row, Skeleton, Spin, Statistic, Switch, Tooltip } from 'antd'
import * as Unicons from '@iconscout/react-unicons';

import { getOldDate, todayEndDate, todayStartDate } from '../../../helpers/DateUtils';
import { ENDPOINT_DOC_TYPE, ENDPOINT_PERIOD_SALES, LOCAL_FAST_RECEIPT_COMPARE_1, LOCAL_FAST_RECEIPT_COMPARE_2, LOCAL_FAST_RECEIPT_COMPARE_3, PRIMARY_COLOR, TOOLTIP_DIFF_TEXT } from '../../../helpers/Globals';
import getPercentage from '../../../helpers/GetPercentage';
import formatEuro from '../../../helpers/FormatEuro';
import { Context } from '../../../helpers/Context';
import { ApiCall } from '../../../Backoffice';


export default function DisplayFastDailyReceipt() {
   
   const [loadingDocTypes, setLoadingDocTypes] = useState(true);
   const [receiptTypes, setReceiptTypes] = useState([]);

   const [dailyReceiptOldDay3, setDailyReceiptOldDay3] = useState(null);
   const [dailyReceiptOldDay1, setDailyReceiptOldDay1] = useState(null);
   const [dailyReceiptOldDay2, setDailyReceiptOldDay2] = useState(null);
   const [diffReceiptOldDay3, setDiffReceiptOldDay3] = useState(null);
   const [diffReceiptOldDay1, setDiffReceiptOldDay1] = useState(null);
   const [diffReceiptOldDay2, setDiffReceiptOldDay2] = useState(null);
   const [dailyReceipt, setDailyReceipt] = useState(null);
   
   const [loadingDiff1, setLoadingDiff1] = useState(true); 
   const [loadingDiff2, setLoadingDiff2] = useState(true); 
   const [loadingDiff3, setLoadingDiff3] = useState(true); 

   
  // --------- setto i giorni di deafult per le statistiche rapide e personalizzabili ---------
  const [oldDay1, setOldDay1] = useState(localStorage.getItem(LOCAL_FAST_RECEIPT_COMPARE_1) ? localStorage.getItem(LOCAL_FAST_RECEIPT_COMPARE_1) : 1); 
  const [oldDay2, setOldDay2] = useState(localStorage.getItem(LOCAL_FAST_RECEIPT_COMPARE_2) ? localStorage.getItem(LOCAL_FAST_RECEIPT_COMPARE_2) : 7); 
  const [oldDay3, setOldDay3] = useState(localStorage.getItem(LOCAL_FAST_RECEIPT_COMPARE_3) ? localStorage.getItem(LOCAL_FAST_RECEIPT_COMPARE_3) : 28); 
  // --------- setto i giorni di deafult per le statistiche rapide e personalizzabili ---------

   
   const [showReceiptPercentage, setShowReceiptPercentage] = useState(false);


   // ---- variabili dal context -----
   const {
      commonQueryParams,
      refreshVariables,
      useCurrentTime,
      isRestaurant,
      accessToken,
      userType,
   } = useContext(Context);
   // ---- variabili dal context -----
   
     
   //---------------------  chiamata per ottenere i tipi di documento solo per i ristoranti --------------------- 
   useEffect(() => {
    
      setLoadingDocTypes(true);

      if (!isRestaurant) return;
      (async () => {
         const json = await ApiCall(`${ENDPOINT_DOC_TYPE}?date=${todayStartDate}&end_date=${todayEndDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
         if (json) {
            setReceiptTypes(json);
            setLoadingDocTypes(false);
         }
      })();

   }, [...refreshVariables])
   
   // ---------------------  chiamata giornaliera per ricevere i dati delle ${ENDPOINT_PERIOD_SALES} da cui ottengo il totale venduto giornaliero, il totale clienti serviti, prodotti venduti e documenti emessi --------------------- 
   useEffect(() => {

      (async () => {
         const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${dayjs().format('YYYY-MM-DDT00:00:00')}&end_date=${dayjs().format('YYYY-MM-DDT23:59:59')}&${commonQueryParams}`, "GET", `${accessToken}`, null)
      
         if (json) {
            var sales = json.sales
            setDailyReceipt(sales.length);
         }
      })();
      
   }, [...refreshVariables]);

   // ---------------------  chiamata per ricevere le statistiche rapide con il primo valore --------------------- 
   useEffect(() => {
      if (dailyReceipt === null) return;

      setLoadingDiff1(true);
      
      var fullOldDate = getOldDate(oldDay1);
      var oldDate = fullOldDate.split("T", 1)[0];

      if (!useCurrentTime) fullOldDate = oldDate + "T23:59:59"; //se il filtro useCurrentTime è disattivo prendo i dati dei giorni precedenti di tutta la giornata, altrimenti confronto i dati fino all'ora corrente di oggi


      (async () => {
         const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${oldDate + "T00:00:00"}&end_date=${fullOldDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
          
          
         if (json) {
            var sales = json.sales
            if(sales.length == dailyReceiptOldDay1)  setLoadingDiff1(false);
            setDailyReceiptOldDay1(sales.length);
         }
      })();
      
   }, [...refreshVariables, useCurrentTime, dailyReceipt, oldDay1]);
   // ---------------------  chiamata per ricevere le statistiche rapide con il primo valore --------------------- 


   // ---------------------  chiamata per ricevere le statistiche rapide con il secondo valore --------------------- 
   useEffect(() => {
      if (dailyReceipt === null) return;
  
      setLoadingDiff2(true);

      var fullOldDate = getOldDate(oldDay2);
      var oldDate = fullOldDate.split("T", 1)[0];

      if (!useCurrentTime) fullOldDate = oldDate + "T23:59:59"; //se il filtro useCurrentTime è disattivo prendo i dati dei giorni precedenti di tutta la giornata, altrimenti confronto i dati fino all'ora corrente di oggi


      (async () => {
         const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${oldDate + "T00:00:00"}&end_date=${fullOldDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
         if (json) {
            var sales = json.sales
            if(sales.length == dailyReceiptOldDay2)  setLoadingDiff2(false);
            setDailyReceiptOldDay2(sales.length);
         }
      })();

      
   }, [...refreshVariables, useCurrentTime, dailyReceipt, oldDay2]);
   // ---------------------  chiamata per ricevere le statistiche rapide con il secondo valore --------------------- 

   // --------------------- chiamata per ricevere le statistiche rapide con il terzo valore --------------------- 
   useEffect(() => {
      if (dailyReceipt === null) return;

      setLoadingDiff3(true);
      
      var fullOldDate = getOldDate(oldDay3);
      var oldDate = fullOldDate.split("T", 1)[0];

      if (!useCurrentTime) fullOldDate = oldDate + "T23:59:59"; //se il filtro useCurrentTime è disattivo prendo i dati dei giorni precedenti di tutta la giornata, altrimenti confronto i dati fino all'ora corrente di oggi


      (async () => {
         const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${oldDate + "T00:00:00"}&end_date=${fullOldDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
          
         if (json) {
            var sales = json.sales
            if(sales.length == dailyReceiptOldDay3)  setLoadingDiff3(false);
            setDailyReceiptOldDay3(sales.length);
         }
      })();
      
    }, [...refreshVariables, useCurrentTime, dailyReceipt, oldDay3]);
   // --------------------- chiamata per ricevere le statistiche rapide con il terzo valore --------------------- 

   // ---------------------  settaggio dei dati giorni precedenti --------------------- 
   useEffect(() => {
      if (dailyReceipt === null || dailyReceiptOldDay1 === null) return;
      setLoadingDiff1(true);
      setDiffReceiptOldDay1(getPercentage(dailyReceipt, dailyReceiptOldDay1));
   }, [dailyReceiptOldDay1])

   useEffect(() => {
      if (dailyReceipt === null || dailyReceiptOldDay2 === null) return;
      setLoadingDiff2(true);
      setDiffReceiptOldDay2(getPercentage(dailyReceipt, dailyReceiptOldDay2));
   }, [dailyReceiptOldDay2])

   useEffect(() => {
      if (dailyReceipt === null || dailyReceiptOldDay3 === null) return;
      setLoadingDiff3(true);
      setDiffReceiptOldDay3(getPercentage(dailyReceipt, dailyReceiptOldDay3));
   }, [dailyReceiptOldDay3])

   useEffect(() => {
      if (diffReceiptOldDay1 === null ) return;
      setLoadingDiff1(false);
   }, [diffReceiptOldDay1])
   useEffect(() => {
      if (diffReceiptOldDay2 === null) return;
      setLoadingDiff2(false);
   }, [diffReceiptOldDay2])
   useEffect(() => {
      if ( diffReceiptOldDay3 === null) return;
      setLoadingDiff3(false);
   }, [diffReceiptOldDay3])
   
// ----------- contenuto del popover per cambiare le comparazioni -----------
  
  const [showCalendar, setShowCalendar] = useState(true);
  const [popoverOldDay1, setPopoverOldDay1] = useState(1); 
  const [popoverOldDay2, setPopoverOldDay2] = useState(1); 
  const [popoverOldDay3, setPopoverOldDay3] = useState(1); 
  
  const popoverContent = (param) => (
    <div>
      <p>{TOOLTIP_DIFF_TEXT}</p>
      <div className='flex gap-5 align-center'>
          {!showCalendar ? <InputNumber
            min={1}
            prefix="-"
            defaultValue={
              param === 1 ? oldDay1 :
              param === 2 ? oldDay2 :
              param === 3 ? oldDay3 :
              1
            }
            onChange={(value) => {
              switch (param) {
                case 1:
                  setPopoverOldDay1(value)
                  break;
                case 2:
                  setPopoverOldDay2(value)
                  break;
                case 3:
                  setPopoverOldDay3(value)
                  break;
              
                default:
                  break;
              }
            }
            }
            changeOnWheel
           />
            :
           <DatePicker
            size='medium'
            placeholder='Scegli giorno'
            onChange={(value) => {
                var day = dayjs(value).diff(dayjs(), "day", true).toString().split(".")[0].replace("-","");
                if (day === "un") day = 1;
                switch (param) {
                  case 1:
                    setPopoverOldDay1(day)
                    break;
                  case 2:
                    setPopoverOldDay2(day)
                    break;
                  case 3:
                    setPopoverOldDay3(day)
                    break;
                
                  default:
                    break;
                }
              }
            }
          />}
          <Button
            type='primary'
            size='medium'
            onClick={() => {
              
              localStorage.setItem(
                param === 1 ? LOCAL_FAST_RECEIPT_COMPARE_1 :
                param === 2 ? LOCAL_FAST_RECEIPT_COMPARE_2 :
                param === 3 ? LOCAL_FAST_RECEIPT_COMPARE_3 :
                "",
                param === 1 ? popoverOldDay1 :
                param === 2 ? popoverOldDay2 :
                param === 3 ? popoverOldDay3 :
                ""
              )

                switch (param) {
                case 1:
                  setOldDay1(popoverOldDay1)
                  break;
                case 2:
                  setOldDay2(popoverOldDay2)
                  break;
                case 3:
                  setOldDay3(popoverOldDay3)
                  break;
              
                default:
                  break;
                }
              
            }}
          >
            Salva
         </Button>
         <Switch
          checkedChildren="n"
          unCheckedChildren={<CalendarOutlined />}
          onChange={()=>setShowCalendar(!showCalendar)}
        />
      </div>
    </div>
  );
  // ----------- contenuto del popover per cambiare le comparazioni ----------- 

   
  return (
     <div className="di-card h-100">
      <Spin className='absolute bottom-20 right-30' spinning={loadingDiff1 || loadingDiff2 || loadingDiff3} />
        <Row gutter={[15, 15]} justify="center" align="middle">
           
         <Unicons.UilShoppingCartAlt className="color-primary"/>
           
         <Col align="center">
         <div className='fs-18 fw-5'>Documenti emessi oggi</div>
         </Col>
   
         <Col align="center">
         <div className='flex gap-5 align-center'>
            <Switch
               checkedChildren="%"
               unCheckedChildren="n"
               onChange={()=>setShowReceiptPercentage(showReceiptPercentage ? false : true)}
            />
         </div>
         </Col>
   
      </Row>
      <Divider className='mt-10 mb-10'/>
      <Row gutter={[15, 15]} justify="center" align="middle">
         <Col xs={24} xl={4} align="center" className='fs-30'>
            <div className="flex gap-10 justify-center">
               {dailyReceipt === null ?
                  <Skeleton.Button active/>
                :
                  <>
                     <span className='fs-30'>
                        {dailyReceipt}
                       </span>
                     {isRestaurant && receiptTypes.length > 0 && 
                     <>
                        {!loadingDocTypes ?
                              <div className="flex gap-10 cursor-pointer">
                                 {receiptTypes.map((e, index) => {
                                 return (
                                       <Tooltip title={`Emessi: ${e.number_of_doc}`} key={`docType-${index}`} className='fs-15 cursor-pointer'>
                                       {e.type} <br />
                                       {formatEuro(e.amount)}€
                                       </Tooltip>
                                    )
                                 })
                                 }
                              </div>
                           :
                              <div className='flex align-center'>
                                 <Spin
                                 indicator={
                                    <LoadingOutlined
                                       style={{
                                       fontSize: 24,
                                       }}
                                       spin
                                    />
                                 }
                                 />
                              </div>
                           }
                        </>
                       }
                  </>
               }   
            </div>
         </Col>
      </Row>
   
      <Divider className='mt-10 mb-10' />
      <Row justify="center"><div className='fs-12 color-grey'>{showReceiptPercentage ? "Confronti" : "Totali" }</div></Row>
      <Row gutter={[15, 15]} justify="space-around" align="middle">
           
         <Popover
            className='cursor-pointer'
            content={()=>popoverContent(1)}
            trigger={"click"}
         >
            <Col align="center">
            {showReceiptPercentage ?
               <Statistic
                  groupSeparator="."
                  decimalSeparator=','
                  title={`-${oldDay1}gg`}
                  loading={loadingDiff1}
                  value={diffReceiptOldDay1}
                  valueStyle={{
                  color: diffReceiptOldDay1>0?'#3f8600':'#cf1322',
                  }}
                  prefix={ 
                  diffReceiptOldDay1 > 0 ? <ArrowUpOutlined />
                  :
                  diffReceiptOldDay1 < 0 ? <ArrowDownOutlined />
                  :
                  ""
                  }
                  suffix="%"
               />
               :
               <Statistic
                  groupSeparator="."
                  decimalSeparator=','
                  title={`-${oldDay1}gg`}
                  loading={loadingDiff1}
                  value={dailyReceiptOldDay1}
                  valueStyle={{color: PRIMARY_COLOR}}
               />
            }
            </Col>
         </Popover>
         
         <Popover
            className='cursor-pointer'
            content={()=>popoverContent(2)}
            trigger={"click"}
         >
            <Col align="center">
            {showReceiptPercentage ?
               <Statistic
                  groupSeparator="."
                  decimalSeparator=','
                  title={`-${oldDay2}gg`}
                  loading={loadingDiff2}
                  value={diffReceiptOldDay2}
                  valueStyle={{
                  color: diffReceiptOldDay2 > 0?'#3f8600':'#cf1322',
                  }}
                  prefix={ 
                  diffReceiptOldDay2 > 0 ? <ArrowUpOutlined />
                  :
                  diffReceiptOldDay2 < 0 ? <ArrowDownOutlined />
                  :
                  ""
                  }
                  suffix="%"
               />
               :
               <Statistic
                  groupSeparator="."
                  decimalSeparator=','
                  title={`-${oldDay2}gg`}
                  loading={loadingDiff2}
                  value={dailyReceiptOldDay2}
                  valueStyle={{color: PRIMARY_COLOR}}
               />
            }
            </Col>
         </Popover>
         
          <Popover
            className='cursor-pointer'
            content={()=>popoverContent(3)}
            trigger={"click"}
         >
            <Col align="center">
            {showReceiptPercentage ?
               <Statistic
                  groupSeparator="."
                  decimalSeparator=','
                  title={`-${oldDay3}gg`}
                  loading={loadingDiff3}
                  value={diffReceiptOldDay3}
                  valueStyle={{
                  color: diffReceiptOldDay3>0?'#3f8600':'#cf1322',
                  }}
                  prefix={ 
                  diffReceiptOldDay3 > 0 ? <ArrowUpOutlined />
                  :
                  diffReceiptOldDay3 < 0 ? <ArrowDownOutlined />
                  :
                  ""
                  }
                  suffix="%"
               />
               :
               <Statistic
                  groupSeparator="."
                  decimalSeparator=','
                  title={`-${oldDay3}gg`}
                  loading={loadingDiff3}
                  value={dailyReceiptOldDay3}
                  valueStyle={{color: PRIMARY_COLOR}}
               />
            }
            </Col>
         </Popover>
      </Row>
   </div>
  )
}
